import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// material-ui
import {Stack} from '@mui/material';

// project import
import Settings from './Settings';
import LogoSection from '../../../../commons/components/Logo/LogoSection';
import TitleDashboard from '../../../../commons/components/TitleDashboard/TitleDashboard';
import MenuDashboard from '../../../../commons/components/MenuDashboard/MenuDashboard';

import {mainContentPaddingHeight, mainContentPaddingWidth} from '../../../../config';
import {getAccountSfid} from '../../../../app/store/reducers/session';
import {getAccounts} from '../../../../app/store/reducers/entities';
import {getDashboardAccount, setDashboard} from '../../../../app/store/reducers/dashboard';
import {activeItem} from '../../../../app/store/reducers/menu';
import {useLazyGetChildAccountsQuery} from '../../../../features/coreApi/apiSlice';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const dispatch = useDispatch();
  const accountSfid = useSelector(getAccountSfid);
  const accounts = useSelector(getAccounts);

  const dashboardAccount = useSelector(getDashboardAccount);
  const [fetchChildAccounts] = useLazyGetChildAccountsQuery();

  useEffect(() => {
    if (dashboardAccount.sfid) {
      fetchChildAccounts(dashboardAccount.sfid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAccount]);

  useEffect(() => {
    let dashboardInfo = {sfid: null, name: null};

    if (accounts.length > 0 && !dashboardAccount.sfid && accountSfid) {
      const selectedAccount = accounts.find((account) => account.sfid === accountSfid);
      dashboardInfo = {sfid: selectedAccount.sfid, name: selectedAccount.name};

      dispatch(setDashboard({type: 'portfolio', data: dashboardInfo}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, dashboardAccount, accountSfid]);

  useEffect(() => {
    dispatch(activeItem('portfolio'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      direction="column"
      rowGap={1}
      sx={{width: '100%', height: '100%', p: `${mainContentPaddingHeight}px ${mainContentPaddingWidth}px`}}
    >
      <LogoSection />
      <Stack direction="row" justifyContent="space-between" alignItems="center" columnGap={4} sx={{width: '100%', height: 'fit-content'}}>
        <TitleDashboard />
        <Stack direction="row" justifyContent="flex-end" columnGap={1} alignItems="flex-start" sx={{width: '360px', minWidth: '360px'}}>
          <Settings />
        </Stack>
      </Stack>
      <MenuDashboard />
    </Stack>
  );
};

export default HeaderContent;
