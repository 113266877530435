import rison from 'rison';

import {actionsApiSlice} from './apiSlice';
import {VISIBLE_PROPERTY_STATUSES} from '../../commons/constants/communityConstants';

export const campaignsSlice = actionsApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignsByAccountSfid: builder.query({
      query: (sfid) => {
        const risonObj = rison.encode_uri({filters: [{col: 'account_id', opr: 'eq', value: sfid}]});
        return {
          url: 'campaigns/?q=' + risonObj,
          method: 'get'
        };
      },
      transformResponse: (response) => {
        const mapped =
          response?.result
            .filter((c) => VISIBLE_PROPERTY_STATUSES.includes(c?.rental_community?.property_status__c))
            .map((c) => ({
              ...c,
              property_sfid: c.rental_community.sfid,
              sfid: c.id,
              name: c?.name || `${c.rental_community.name} - ${c.subtype}`
            })) || [];
        return {...response, result: mapped};
      },
      providesTags: [{type: 'Campaigns', id: 'LIST'}]
    }),
    getCampaignById: builder.query({
      query: (id) => {
        return {
          url: 'campaigns/' + id,
          method: 'get'
        };
      },
      providesTags: (_result, _error, {id}) => [{type: 'Campaigns', id}]
    }),
    getSupportedCampaignTypes: builder.query({
      query: (id) => {
        return {
          url: `campaigns/supported/${id}`,
          method: 'get'
        };
      }
    }),
    getPotentialGoals: builder.query({
      query: (key) => {
        return {
          url: `campaigns/${key}/potential_goals/`,
          method: 'get'
        };
      }
    }),
    getRecommendedCampaigns: builder.query({
      query: ({campaignType, data}) => {
        return {
          method: 'get',
          url: `campaigns/${campaignType}/goal_first`,
          params: data
        };
      }
    }),
    postRecommendedCampaign: builder.mutation({
      query: ({campaignType, data}) => {
        return {
          method: 'post',
          url: `campaigns/${campaignType}/goal_first`,
          data,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
      }
    })
  }),
  overrideExisting: false
});

export const {
  useLazyGetCampaignsByAccountSfidQuery,
  useLazyGetCampaignByIdQuery,
  useLazyGetSupportedCampaignTypesQuery,
  useLazyGetPotentialGoalsQuery,
  useLazyGetRecommendedCampaignsQuery,
  usePostRecommendedCampaignMutation
} = campaignsSlice;
