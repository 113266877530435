import React, {lazy} from 'react';

// project import
import Loadable from '../commons/components/Loadable';
// lazy load
const HomePage = Loadable(lazy(() => import('../pages/Home/HomePage')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = [{path: '/home', element: <HomePage />}];

export default MainRoutes;
