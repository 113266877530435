import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Skeleton, Tooltip, Typography, useMediaQuery, useTheme} from '@mui/material';
import {activeComponent, getOpenComponent, getOpenItem} from '../../../app/store/reducers/menu';
import {getDashboardAccount, getDashboardCampaign, getDashboardCommunity} from '../../../app/store/reducers/dashboard';

const TitleDashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchDown = useMediaQuery(theme.breakpoints.down('lg'));
  const openItem = useSelector(getOpenItem);
  const openComponent = useSelector(getOpenComponent);

  const dashboardAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);
  const dashboardCampaign = useSelector(getDashboardCampaign);

  const onClick = () => {
    if (openComponent !== 'home') {
      dispatch(activeComponent('home'));
    }
  };

  const pageTitle = useMemo(() => {
    let componentInfo = {
      name: '',
      sfid: ''
    };

    if (openItem) {
      switch (openItem) {
        case 'portfolio':
          componentInfo = dashboardAccount;
          break;
        case 'communities':
          componentInfo = dashboardCommunity;
          break;
        case 'campaigns':
          componentInfo = dashboardCampaign;
          break;
        default:
          componentInfo = {
            name: '',
            sfid: null
          };
      }
    }

    if (componentInfo.sfid && componentInfo.name) {
      if (componentInfo.name.length > 30 && matchDown) {
        return (
          <Tooltip title={componentInfo.name} sx={{display: 'inline-flex', height: 'fit-content'}}>
            <Typography
              variant={matchDown ? 'h3' : 'h2'}
              color="secondary"
              fontWeight={'fontWeightRegular'}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '99%',
                cursor: openComponent !== 'home' ? 'pointer' : 'default'
              }}
              onClick={onClick}
            >
              {componentInfo.name}
            </Typography>
          </Tooltip>
        );
      } else {
        return (
          <Typography
            variant={matchDown ? 'h3' : 'h2'}
            color="secondary"
            fontWeight={'fontWeightRegular'}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '99%',
              cursor: openComponent !== 'home' ? 'pointer' : 'default'
            }}
            onClick={onClick}
          >
            {componentInfo.name}
          </Typography>
        );
      }
    } else {
      return <Skeleton variant="text" width={'60%'} height={40} animation="wave" />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAccount, dashboardCommunity, dashboardCampaign, openItem]);

  return pageTitle;
};

export default TitleDashboard;
