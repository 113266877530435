import React from 'react';
// material-ui
import {useTheme} from '@mui/material/styles';
import {Toolbar} from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent/HeaderContent';
import {headerHeight} from '../../../config';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
  const theme = useTheme();

  // common header
  const mainHeader = (
    <Toolbar sx={{height: '100%', padding: '0 !important'}}>
      <HeaderContent />
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.customShadows.default,
      height: headerHeight
    }
  };

  return (
    <>
      <AppBarStyled open={true} {...appBar}>
        {mainHeader}
      </AppBarStyled>
    </>
  );
};

export default Header;
