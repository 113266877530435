import Cookies from 'universal-cookie';

let cookies = new Cookies();

export const createNewCookie = (key, value, expDate) => {
  removeAllCookies();
  const now = new Date();
  const expirationDate = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

  try {
    // Set the cookie
    cookies.set(key, value, {
      path: '/',
      expires: expirationDate,
      secure: window.location.protocol === 'https:',
      sameSite: 'lax'
    });

    // Immediately try to read the cookie
    const verifiedValue = cookies.get(key);

    // If immediate verification fails, try again after a short delay
    if (verifiedValue !== value) {
      setTimeout(() => {
        const delayedVerifiedValue = cookies.get(key);
        if (delayedVerifiedValue !== value) {
          // Attempt to set the cookie again
          cookies.set(key, value, {
            path: '/',
            expires: expirationDate,
            secure: window.location.protocol === 'https:',
            sameSite: 'lax'
          });
        }
      }, 100);
    }
  } catch (error) {
    console.error('Error setting cookie:', error);
  }
};

export const getCookie = (name) => {
  return cookies.get(name);
};

export const removeCookie = (name) => {
  cookies.remove(name, {path: '/'});
};

export const removeAllCookies = () => {
  const allCookies = cookies.getAll();
  Object.keys(allCookies).forEach((cookie) => {
    cookies.remove(cookie, {path: '/'});
  });
};

const dec2hex = (dec) => {
  return dec.toString(16).padStart(2, '0');
};

export const generateId = (len) => {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getSessionTokenWithRetry = async (retries = 5, interval = 1000) => {
  for (let i = 0; i < retries; i++) {
    const token = getCookie('session_token');
    if (token) return token;
    // Wait for a bit before retrying
    await delay(interval);
  }
  return null;
};

let currentToken = null;

// Initializes the token
export const initializeToken = async () => {
  currentToken = await getSessionTokenWithRetry();
  return currentToken;
};

export const getCurrentToken = () => {
  return currentToken;
};

// New function to clear the current token
export const clearCurrentToken = () => {
  currentToken = null;
};

// New function to refresh the token
export const refreshToken = async () => {
  clearCurrentToken();
  return await initializeToken();
};
