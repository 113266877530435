import {coreApiSlice} from './apiSlice';

export const residentsApi = coreApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getResidentsByAccountSfid: builder.query({
      query: (sfid) => ({
        url: 'residents/list/',
        method: 'get',
        params: {account_id: sfid}
      }),
      providesTags: (result, error, sfid) => [{type: 'Residents', id: sfid}],
      transformResponse: (response) =>
        response && response.length
          ? response
              .filter((resident) => resident.sfid && resident.name && resident.mobile_user_id__c)
              .sort((a, b) => a.name.localeCompare(b.name))
          : []
    }),
    updateResidentInfo: builder.mutation({
      query: ({id, dataForm, ls_user_name}) => ({
        method: 'patch',
        url: `resident/${id}/update/`,
        data: dataForm,
        params: {ls_user_name},
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }),
    updateResidentsPayments: builder.mutation({
      query: (data) => ({
        url: 'residents/request_payment_changes/',
        method: 'post',
        data,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }),
    createResidentTransactions: builder.mutation({
      query: ({id, dataForm}) => ({
        method: 'post',
        url: `resident/${id}/transactions/`,
        data: dataForm,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }),
      invalidatesTags: (result, error, {id}) => (!error ? [{type: 'ResidentTransactions', id}] : [])
    }),
    getResidentTransactions: builder.query({
      query: (id) => ({
        url: `resident/${id}/transactions/all/`,
        method: 'get'
      }),
      providesTags: (result, error, id) => (!error ? [{type: 'ResidentTransactions', id}] : [])
    }),
    getResidentPaymentsHistory: builder.query({
      query: ({id, token}) => ({
        url: `resident_payment_histories/${id}/`,
        method: 'get',
        headers: {
          auth: token
        }
      }),
      providesTags: (result, error, id) => (!error ? [{type: 'ResidentPayments', id}] : [])
    }),
    getUserToken: builder.query({
      query: (mobile_user_id) => ({
        url: `../mobile/get_transfer_token/`,
        method: 'get',
        headers: {
          userId: mobile_user_id
        }
      })
    })
  }),
  overrideExisting: false
});

export const {
  useLazyGetResidentsByAccountSfidQuery,
  useGetResidentsByAccountSfidQuery,
  useUpdateResidentInfoMutation,
  useUpdateResidentsPaymentsMutation,
  useGetResidentTransactionsQuery,
  useCreateResidentTransactionsMutation,
  useGetResidentPaymentsHistoryQuery,
  useLazyGetUserTokenQuery
} = residentsApi;
