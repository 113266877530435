import {createSlice} from '@reduxjs/toolkit';
import {residentsApi} from '../../../features/coreApi/residentsSlice';
import {campaignsSlice} from '../../../features/actionsApi/campaignsSlice';
import {communitiesApi} from '../../../features/coreApi/communitiesSlice';
import {sessionApi} from '../../../features/loyaltyCloudApi/session';

const initialState = {
  residents: {
    isLoading: false,
    isSuccess: false,
    isError: false
  },
  campaigns: {
    isLoading: false,
    isSuccess: false,
    isError: false
  },
  communities: {
    isLoading: false,
    isSuccess: false,
    isError: false
  },
  tokenVerification: {
    isLoading: false,
    isSuccess: false,
    isError: false
  }
};

const loadingSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    resetLoadingState: () => {
      return initialState;
    },

    resetElementLoadingState: (state, {payload}) => {
      state[payload].isLoading = false;
      state[payload].isSuccess = false;
      state[payload].isError = false;
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(residentsApi.endpoints.getResidentsByAccountSfid.matchRejected, (state) => {
        state.residents.isError = true;
        state.residents.isLoading = false;
        state.residents.isSuccess = false;
      })
      .addMatcher(residentsApi.endpoints.getResidentsByAccountSfid.matchPending, (state) => {
        state.residents.isLoading = true;
        state.residents.isError = false;
        state.residents.isSuccess = false;
      })
      .addMatcher(residentsApi.endpoints.getResidentsByAccountSfid.matchFulfilled, (state) => {
        state.residents.isSuccess = true;
        state.residents.isLoading = false;
        state.residents.isError = false;
      })
      .addMatcher(campaignsSlice.endpoints.getCampaignsByAccountSfid.matchRejected, (state) => {
        state.campaigns.isError = true;
        state.campaigns.isLoading = false;
        state.campaigns.isSuccess = false;
      })
      .addMatcher(campaignsSlice.endpoints.getCampaignsByAccountSfid.matchPending, (state) => {
        state.campaigns.isLoading = true;
        state.campaigns.isError = false;
        state.campaigns.isSuccess = false;
      })
      .addMatcher(campaignsSlice.endpoints.getCampaignsByAccountSfid.matchFulfilled, (state) => {
        state.campaigns.isSuccess = true;
        state.campaigns.isLoading = false;
        state.campaigns.isError = false;
      })
      .addMatcher(communitiesApi.endpoints.getCommunitiesByAccountSfid.matchRejected, (state) => {
        state.communities.isError = true;
        state.communities.isLoading = false;
        state.communities.isSuccess = false;
      })
      .addMatcher(communitiesApi.endpoints.getCommunitiesByAccountSfid.matchPending, (state) => {
        state.communities.isLoading = true;
        state.communities.isError = false;
        state.communities.isSuccess = false;
      })
      .addMatcher(communitiesApi.endpoints.getCommunitiesByAccountSfid.matchFulfilled, (state) => {
        state.communities.isSuccess = true;
        state.communities.isLoading = false;
        state.communities.isError = false;
      })
      .addMatcher(sessionApi.endpoints.getSessionInfo.matchPending, (state) => {
        state.tokenVerification.isLoading = true;
        state.tokenVerification.isError = false;
        state.tokenVerification.isSuccess = false;
      })
      .addMatcher(sessionApi.endpoints.getSessionInfo.matchFulfilled, (state) => {
        state.tokenVerification.isSuccess = true;
        state.tokenVerification.isLoading = false;
        state.tokenVerification.isError = false;
      })
      .addMatcher(sessionApi.endpoints.getSessionInfo.matchRejected, (state) => {
        state.tokenVerification.isError = true;
        state.tokenVerification.isLoading = false;
        state.tokenVerification.isSuccess = false;
      })
      .addMatcher(sessionApi.endpoints.getSsoSessionInfo.matchPending, (state) => {
        state.tokenVerification.isLoading = true;
        state.tokenVerification.isError = false;
        state.tokenVerification.isSuccess = false;
      })
      .addMatcher(sessionApi.endpoints.getSsoSessionInfo.matchFulfilled, (state) => {
        state.tokenVerification.isSuccess = true;
        state.tokenVerification.isLoading = false;
        state.tokenVerification.isError = false;
      })
      .addMatcher(sessionApi.endpoints.getSsoSessionInfo.matchRejected, (state) => {
        state.tokenVerification.isError = true;
        state.tokenVerification.isLoading = false;
        state.tokenVerification.isSuccess = false;
      });
  }
});

export const {resetLoadingState, resetElementLoadingState} = loadingSlice.actions;
export default loadingSlice.reducer;

export const getResidentLoadingStatus = (state) => state.loading.residents;
export const getCampaignLoadingStatus = (state) => state.loading.campaigns;
export const getCommunityLoadingStatus = (state) => state.loading.communities;
export const getTokenVerificationLoadingStatus = (state) => state.loading.tokenVerification;
