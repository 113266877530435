// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Switch() {
  return {
    MuiSwitch: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: '#3F51B5'
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#3F51B5'
          }
        },
        colorSuccess: {
          '&.Mui-checked': {
            color: '#15CB12'
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#15CB12',
            opacity: 0.4
          }
        }
      }
    }
  };
}
