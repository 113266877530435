import {createSlice} from '@reduxjs/toolkit';
import {createNewCookie, getCookie, initializeToken, removeCookie} from '../../../commons/utils/cookies';
import {sessionApi} from '../../../features/loyaltyCloudApi/session';
import {actionsApiSlice} from '../../../features/actionsApi/apiSlice';
import {loyaltyCloudApi} from '../../../features/loyaltyCloudApi/apiSlice';

const initialState = {
  accountSfid: null,
  isAuthorized: null,
  sessionToken: null,
  email: null,
  isStakeAdmin: false,
  isResident: false,
  lcTeamMemberId: null,
  loginError: '',
  loginSuccess: '',
  securityToken: null,
  propertyPermissions: [],
  accountsPermissions: [],
  firstName: null,
  lastName: null,
  role: null
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetSession: () => {
      removeCookie('session_token');
      removeCookie('expires_at');
      return initialState;
    },
    setupResident: (state, action) => {
      state.isResident = action.payload;
    },
    setAuthorization: (state) => {
      const sessionToken = getCookie('session_token');
      if (sessionToken) {
        state.isAuthorized = true;
        state.sessionToken = sessionToken;
      }
    },
    setAccountSfid: (state, action) => {
      state.accountSfid = action.payload;
    },
    setPermissions: (state, action) => {
      state.propertyPermissions = action.payload.propertyPermissions;
      state.accountsPermissions = action.payload.accountsPermissions;
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(sessionApi.endpoints.updateLoginSession.matchFulfilled, (state, {payload}) => {
        const isResident = payload === 'resident member';
        state.isResident = isResident;
        state.loginSuccess = isResident ? 'Please login in our Stake app' : payload[0].toUpperCase() + payload.slice(1);
      })
      .addMatcher(sessionApi.endpoints.updateLoginSession.matchRejected, (state, {payload}) => {
        state.loginError = payload.data.error;
      })
      .addMatcher(sessionApi.endpoints.updateSignUpSession.matchFulfilled, (state, {payload}) => {
        const isResident = payload === 'resident member';
        state.isResident = isResident;
        state.loginSuccess = isResident ? 'Please sign up in our Stake app' : payload[0].toUpperCase() + payload.slice(1);
      })
      .addMatcher(sessionApi.endpoints.updateSignUpSession.matchRejected, (state, {payload}) => {
        state.loginError = payload.data.error;
      })
      .addMatcher(sessionApi.endpoints.updateSsoLoginSession.matchFulfilled, (state, {payload}) => {
        const isErrored = payload == 'payload error_log_in - The email address you submitted is not configured to use SSO.';
        if (isErrored) {
          state.loginError = payload.replace('payload error_log_in - ', '');
        } else {
          state.loginSuccess = 'It will redirect to the SSO login page. Please wait...';
        }
      })
      .addMatcher(sessionApi.endpoints.updateSsoLoginSession.matchRejected, (state, {payload}) => {
        state.loginError = payload.data.error;
      })
      .addMatcher(sessionApi.endpoints.getSessionInfo.matchFulfilled, (state, {payload}) => {
        const {session_token, session} = payload;

        state.isAuthorized = true;
        state.sessionToken = session_token;
        state.accountSfid = session.attributes.associated_sfid;

        createNewCookie('session_token', session_token, session.expires_at);
        initializeToken();
      })
      .addMatcher(sessionApi.endpoints.getSsoSessionInfo.matchFulfilled, (state, {payload}) => {
        const {session_token, session} = payload;

        state.isAuthorized = true;
        state.sessionToken = session_token;
        state.accountSfid = session.attributes.associated_sfid;

        createNewCookie('session_token', session_token, session.expires_at);
        initializeToken();
      })
      .addMatcher(actionsApiSlice.endpoints.getSecurityToken.matchFulfilled, (state, {payload}) => {
        state.securityToken = payload.access_token;
      })
      .addMatcher(loyaltyCloudApi.endpoints.getTeamMemberInfo.matchFulfilled, (state, {payload}) => {
        state.propertyPermissions = payload.property_permissions;
        state.accountsPermissions = payload.additional_accounts_permissions;
        state.isStakeAdmin = payload.master;
        state.email = payload.email;
        state.lcTeamMemberId = payload.id;
        state.accountSfid = payload.associated_sfid;
        state.firstName = payload.first_name;
        state.lastName = payload.last_name;
        state.role = payload.role;
      })
      .addMatcher(loyaltyCloudApi.endpoints.updateTeamMemberPersonalInfo.matchFulfilled, (state, {payload}) => {
        state.email = payload.email;
        state.firstName = payload.first_name;
        state.lastName = payload.last_name;
      });
  }
});

export const {resetSession, setupResident, setAuthorization, setAccountSfid, setPermissions} = sessionSlice.actions;
export default sessionSlice.reducer;

export const getSession = (state) => state.session;
export const getSessionToken = (state) => state.session.sessionToken;
export const getUserType = (state) => state.session.userType;
export const getLoginError = (state) => state.session.loginError;
export const getLoginSuccess = (state) => state.session.loginSuccess;
export const getAuthorizedStatus = (state) => state.session.isAuthorized;
export const getResidentStatus = (state) => state.session.isResident;
export const getLcTeamMemberId = (state) => state.session.lcTeamMemberId;
export const getSecurityToken = (state) => state.session.securityToken;
export const getAccountSfid = (state) => state.session.accountSfid;
export const getPropertyPermissions = (state) => state.session.propertyPermissions;
export const getAccountsPermissions = (state) => state.session.accountsPermissions;
export const getIsStakeAdmin = (state) => state.session.isStakeAdmin;
export const getEmail = (state) => state.session.email;
export const getTeamMemberRole = (state) => state.session.role;
