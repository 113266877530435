// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip(theme) {
  return {
    MuiChip: {
      variants: [
        {
          props: {variant: 'outlined', color: 'primary'},
          style: {
            color: '#3F51B5',
            borderColor: '#3F51B5'
          }
        },
        {
          props: {variant: 'filled', color: 'primary'},
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            borderColor: 'rgba(0, 0, 0, 0.08)',
            color: theme.palette.primary.main
          }
        },
        {
          props: {variant: 'filled', color: 'success'},
          style: {
            backgroundColor: 'rgba(160, 217, 159, 0.5)',
            borderColor: 'rgba(160, 217, 159, 0.5)',
            color: '#11a10e'
          }
        }
      ],
      styleOverrides: {
        root: {
          borderRadius: 16,
          '&:active': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          fontSize: '1rem',
          height: 40
        },
        light: {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.lighter,
          borderColor: theme.palette.primary.light,
          '&.MuiChip-lightError': {
            color: theme.palette.error.main,
            backgroundColor: theme.palette.error.lighter,
            borderColor: theme.palette.error.light
          },
          '&.MuiChip-lightSuccess': {
            color: theme.palette.success.main,
            backgroundColor: theme.palette.success.lighter,
            borderColor: theme.palette.success.light
          },
          '&.MuiChip-lightWarning': {
            color: theme.palette.warning.main,
            backgroundColor: theme.palette.warning.lighter,
            borderColor: theme.palette.warning.light
          }
        }
      }
    }
  };
}
