import React, {lazy, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Alert, Stack, Typography, useMediaQuery, useTheme} from '@mui/material';
import {getLoginError, getLoginSuccess, resetSession} from '../../app/store/reducers/session';
import Loadable from '../../commons/components/Loadable';

const Login = Loadable(lazy(() => import('./Login')));
const Register = Loadable(lazy(() => import('./Register')));
const AuthWrapper = Loadable(lazy(() => import('./AuthWrapper')));
const AuthPageHeader = Loadable(lazy(() => import('./AuthPageHeader')));
const AuthCardWrapper = Loadable(lazy(() => import('./AuthCardWrapper')));
const SsoLogin = Loadable(lazy(() => import('./SsoLogin')));
const ResidentMsg = Loadable(lazy(() => import('./ResidentMsg')));

const AuthPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const loginError = useSelector(getLoginError);
  const loginSuccess = useSelector(getLoginSuccess);

  const [authStatus, setAuthStatus] = useState({
    isLoginSuccess: false,
    isLoginFilled: false,
    isSignUpSuccess: false,
    isSignUpFilled: false,
    isSsoLoginSuccess: false,
    isSsoLoginFilled: false
  });
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isSsoOpen, setSsoOpen] = useState(false);
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const currentHeight = matchDownSM ? 50 : 106;

  const handleReset = () => {
    setAuthStatus({
      isLoginSuccess: false,
      isLoginFilled: false,
      isSignUpSuccess: false,
      isSignUpFilled: false,
      isSsoLoginSuccess: false,
      isSsoLoginFilled: false
    });
    setSignUpOpen(false);
    setSsoOpen(false);
    dispatch(resetSession());
  };

  const renderAuthState = () => {
    if (authStatus.isLoginFilled || authStatus.isSignUpFilled || authStatus.isSsoLoginFilled) {
      const isSuccess = authStatus.isLoginSuccess || authStatus.isSignUpSuccess || authStatus.isSsoLoginSuccess;
      const actionType = authStatus.isLoginFilled ? 'Login' : authStatus.isSignUpFilled ? 'Sign Up' : 'Login with SSO';
      const severity = isSuccess ? 'success' : 'error';
      const message = isSuccess ? loginSuccess : loginError;

      return (
        <Stack direction="column" rowGap={2} justifyContent="center">
          <Typography textAlign="center" variant={matchDownSM ? 'h3' : 'h2'} sx={{fontWeight: 'fontWeightRegular'}}>
            {actionType}
          </Typography>
          <Alert severity={severity} sx={{justifyContent: 'center'}}>
            {message}
          </Alert>
          <Typography variant="caption">
            Go{' '}
            <Typography onClick={handleReset} component={'span'} variant="underline" display="inline">
              back.
            </Typography>
          </Typography>
        </Stack>
      );
    }

    if (isSignUpOpen) {
      return <Register setAuthStatus={setAuthStatus} authStatus={authStatus} handleReset={handleReset} />;
    } else if (isSsoOpen) {
      return <SsoLogin authStatus={authStatus} setAuthStatus={setAuthStatus} handleReset={handleReset} />;
    } else {
      return <Login setAuthStatus={setAuthStatus} setSignUpOpen={setSignUpOpen} authStatus={authStatus} setSsoOpen={setSsoOpen} />;
    }
  };

  return (
    <AuthWrapper>
      <Stack direction="column" sx={{height: '100vh'}}>
        <AuthPageHeader />
        <div style={{height: '100%', width: '100%'}}>
          <Stack
            direction="column"
            rowGap={!matchDownSM ? theme.spacing(2) : 0}
            sx={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              mt: `${-currentHeight}px`
            }}
          >
            <AuthCardWrapper>{renderAuthState()}</AuthCardWrapper>
            <ResidentMsg />
          </Stack>
        </div>
      </Stack>
    </AuthWrapper>
  );
};

export default AuthPage;
