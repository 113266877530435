export const ACTIVE_PROPERTY_STATUSES = [
  'Active',
  'Active P1',
  'Active P2',
  'Active P3',
  'At Risk',
  'Test Prop',
  'Due for Renewal',
  'Not Renewing'
];

export const VISIBLE_PROPERTY_STATUSES = [
  'Active',
  'Active P1',
  'Active P2',
  'Active P3',
  'At Risk',
  'Test Prop',
  'Onboarding',
  'Due for Renewal',
  'Not Renewing',
  'Proposal'
];
