import React from 'react';

import {Box, CircularProgress} from '@mui/material';

const LoadingBox = () => {
  return (
    <Box width="100%" height={200} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress size={20} thickness={1} color="grey" />
    </Box>
  );
};

export default LoadingBox;
