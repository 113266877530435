import React from 'react';
import {useDispatch} from 'react-redux';

import {Box, IconButton} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {fontAwesomeIcons} from '../../../../commons/utils/icons';
import {activeComponent, activeItem, endComponent} from '../../../../app/store/reducers/menu';
import {setDashboard} from '../../../../app/store/reducers/dashboard';

const Settings = () => {
  const dispatch = useDispatch();

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // close all open menu items so it doesn't stay open when navigating to profile
    dispatch(activeItem('portfolio'));
    dispatch(activeComponent('settings'));
    dispatch(endComponent('settings'));
    dispatch(setDashboard({type: 'communities', data: {sfid: null, name: null}}));
    dispatch(setDashboard({type: 'campaigns', data: {sfid: null, name: null}}));
  };

  return (
    <Box sx={{flexShrink: 0, pt: '6px'}}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{color: 'text.primary', bgcolor: 'transparent'}}
        aria-label="open settings"
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <FontAwesomeIcon icon={fontAwesomeIcons.faGear} size="lg" />
      </IconButton>
    </Box>
  );
};

export default Settings;
