import axios from 'axios';
import CONSTANTS from '../../commons/constants/envConstants';
import {actionsApiSlice} from './apiSlice';
import {campaignsSlice} from './campaignsSlice';
import {getCurrentToken} from '../../commons/utils/cookies';

export const offersSlice = actionsApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createNewOffer: builder.mutation({
      query: ({data, sfid}) => ({
        method: 'post',
        url: `campaigns/${sfid}/create_reward_shortcut/`,
        data: JSON.stringify(data)
      }),
      // invalidatesTags: (_result, _error, { sfid }) => [{ type: 'Campaigns', id: sfid ?? 'None' }]
      onQueryStarted: async (newData, {dispatch, queryFulfilled}) => {
        try {
          const {data: createActionResponse} = await queryFulfilled;

          dispatch(
            campaignsSlice.util.updateQueryData('getCampaignById', newData.sfid, (draftCampaign) => {
              draftCampaign.all_actions.push(createActionResponse.action);
            })
          );
        } catch (error) {
          console.error('Failed to create new offer: ', error);
        }
      }
    }),
    editOffer: builder.mutation({
      query: ({data, sfid}) => ({
        method: 'patch',
        url: `campaigns/${sfid}/edit_reward_shortcut/`,
        data: JSON.stringify(data)
      }),
      // invalidatesTags: (_result, _error, { sfid }) => [{ type: 'Campaigns', id: sfid ?? 'None' }]
      onQueryStarted: async (newData, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          const campaign = await axios({
            method: 'get',
            url: CONSTANTS.ACTIONS_API_URL + 'campaigns/' + newData.sfid,
            headers: {
              Accept: 'application/json',
              'X-Session-Token': getCurrentToken()
            }
          });

          dispatch(
            campaignsSlice.util.updateQueryData('getCampaignById', newData.sfid, (draftCampaign) => {
              const actionIndex = draftCampaign.all_actions.findIndex((action) => action.id === newData.data.action_id);

              // Replace the old action data with the new one if found
              if (actionIndex !== -1) {
                draftCampaign.all_actions[actionIndex] = campaign.data.all_actions.find((action) => action.id === newData.data.action_id);
              }
            })
          );
        } catch (error) {
          console.error('Failed to edit offer: ', error);
        }
      }
    }),
    // delete offer
    deleteOffer: builder.mutation({
      query: ({sfid, offerId}) => ({
        method: 'post',
        url: `campaigns/${sfid}/delete_reward/${offerId}/`
      }),
      // invalidatesTags: (_result, _error, { sfid }) => [{ type: 'Campaigns', id: sfid ?? 'None' }],
      onQueryStarted: async ({sfid, actionId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(
            campaignsSlice.util.updateQueryData('getCampaignById', sfid, (draftCampaign) => {
              // Remove the action from the all_actions array by filtering it out
              draftCampaign.all_actions = draftCampaign.all_actions.filter((action) => action.id !== actionId);
            })
          );
        } catch (error) {
          console.error('Failed to delete offer: ', error);
        }
      }
    })
  }),
  overrideExisting: false
});

export const {useCreateNewOfferMutation, useEditOfferMutation, useDeleteOfferMutation} = offersSlice;
