import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';

const MobileLayout = () => {
  const theme = useTheme();
  const drakGrey = theme.palette.grey[600];

  return (
    <Box width="100%" sx={{position: 'absolute', top: '30%', textAlign: 'center', p: 3}}>
      <Typography variant="h5_light" sx={{color: drakGrey, width: '100%'}}>
        Loyalty Cloud Mobile is not yet available, please access on your laptop, or desktop, device.
      </Typography>
    </Box>
  );
};

export default MobileLayout;
