// ==============================|| OVERRIDES - BADGE ||============================== //

export default function Badge() {
  return {
    MuiBadge: {
      styleOverrides: {
        standard: {
          minWidth: 2,
          height: 2,
          padding: 0.5
        }
      }
    }
  };
}
