import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import DashboardComponent from './dashboardComponent';
import {getDashboardAccount} from '../../../app/store/reducers/dashboard';
import {getOpenComponent, getOpenItem} from '../../../app/store/reducers/menu';
import {getAccounts} from '../../../app/store/reducers/entities';

const AccountDashboard = ({createHandleMenuClick}) => {
  const type = 'Portfolio';
  const [accountsData, setAccountsData] = useState([]);

  const dashboardAccount = useSelector(getDashboardAccount);
  const openItem = useSelector(getOpenItem);
  const openComponent = useSelector(getOpenComponent);
  const accounts = useSelector(getAccounts);
  const isActive = openItem === type.toLowerCase() && openComponent == 'home';

  useEffect(() => {
    if (accounts.length > 0) {
      const accountsData = accounts.map((account) => {
        return {
          sfid: account.sfid,
          name: account.name
        };
      });

      setAccountsData([...accountsData]);
    }
  }, [dashboardAccount, accounts]);

  return (
    <DashboardComponent
      type={type}
      dashboardObj={dashboardAccount}
      isLoading={false}
      isSuccess={true}
      data={accountsData}
      createHandleMenuClick={createHandleMenuClick}
      isActive={isActive}
    />
  );
};

AccountDashboard.propTypes = {
  createHandleMenuClick: PropTypes.func
};

export default AccountDashboard;
