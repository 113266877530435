import {coreApiSlice} from './apiSlice';

export const communitiesApi = coreApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommunitiesByAccountSfid: builder.query({
      query: (sfid) => ({
        url: 'rental_community/list/',
        method: 'get',
        params: {account_id: sfid}
      }),
      providesTags: [{type: 'Communities', id: 'LIST'}],
      transformResponse: (response) => {
        // Filter and sort the response using accounts and properties
        const validCommunities = response
          .filter((community) => community.sfid && community.name)
          .sort((a, b) => a.name.localeCompare(b.name));
        return validCommunities;
      }
    }),
    getCommunityBySfid: builder.query({
      query: (sfid) => ({
        url: 'rental_community/',
        method: 'get',
        params: {sfid}
      }),
      providesTags: (_res, err, arg) => !err && [{type: 'Communities', id: arg}]
    }),
    addCommunity: builder.mutation({
      query: (data) => ({
        url: 'rental_community/add/',
        method: 'post',
        data,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }),
    editCommunity: builder.mutation({
      query: ({id, data}) => ({
        url: `rental_community/${id}/update_from_lc_calculator_data/`,
        method: 'patch',
        data
      })
    })
  }),
  overrideExisting: false
});

export const {useLazyGetCommunitiesByAccountSfidQuery, useLazyGetCommunityBySfidQuery, useAddCommunityMutation, useEditCommunityMutation} =
  communitiesApi;
