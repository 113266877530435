import {loyaltyCloudApi} from './apiSlice';

export const reviewsApi = loyaltyCloudApi.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query({
      query: (params) => ({
        url: '/reviews/',
        method: 'get',
        params
      }),
      providesTags: (result) => {
        const baseTag = [{type: 'Reviews', id: 'LIST'}];

        if (result && Array.isArray(result) && result.length > 0) {
          // Map only if result is an array and not empty
          const reviewTags = result.map(({id}) => ({type: 'Reviews', id}));
          return [...reviewTags, ...baseTag];
        }

        return baseTag;
      }
    }),
    createReview: builder.mutation({
      query: (data) => ({
        url: '/reviews/add/',
        method: 'post',
        data: JSON.stringify(data)
      })
    }),
    getReview: builder.query({
      query: (id) => ({
        url: `/review/${id}/`,
        method: 'get'
      }),
      providesTags: (result, error, id) => [{type: 'Reviews', id}]
    }),
    editReview: builder.mutation({
      query: ({data, id}) => ({
        url: `/review/${id}/edit/`,
        method: 'patch',
        data: JSON.stringify(data)
      })
    })
  })
});

export const {useLazyGetReviewsQuery, useGetReviewsQuery, useCreateReviewMutation, useEditReviewMutation} = reviewsApi;
