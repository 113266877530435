// material-ui
import {createTheme} from '@mui/material/styles';

// third-party
import {presetPalettes} from '@ant-design/colors';

// project import
import ThemeOption from './theme/Theme';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode) => {
  const colors = presetPalettes;

  const blue = ['#F2F3F8', '#f1f5f9'];
  const green = ['#00CB52'];

  const greyPrimary = [
    '#ffffff',
    '#f9f9f9',
    '#f5f5f5',
    '#f0f0f0',
    '#e3e3e3',
    '#bfbfbf',
    '#8c8c8c',
    '#787878',
    '#2c2c2c',
    '#141414',
    '#000000'
  ];
  const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  const greyConstant = ['#fafafb', '#e6ebf1'];

  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];
  colors.stake = ['#149CE0', '#D7EEFA'];

  const paletteColor = ThemeOption(colors);

  return createTheme({
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff'
      },
      ...paletteColor,
      text: {
        primary: paletteColor.grey[700],
        secondary: colors.stake[0],
        disabled: paletteColor.grey[400],
        subtitle: paletteColor.grey[600]
      },
      action: {
        disabled: paletteColor.grey[300]
      },
      divider: paletteColor.grey[300],
      background: {
        paper: paletteColor.grey[0],
        default: paletteColor.grey[50]
      },
      blue: {
        0: blue[1],
        100: blue[0]
      },
      green: {
        main: green[0]
      }
    }
  });
};

export default Palette;
