import {createSlice} from '@reduxjs/toolkit';

// initial state
const initialState = {
  openItem: 'portfolio',
  openComponent: null,
  endComponent: null,
  openModal: {type: null, sfid: null},
  openTab: null
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.toLowerCase();
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.toLowerCase();
    },

    endComponent(state, action) {
      state.endComponent = action.payload.toLowerCase();
    },

    activeModal(state, action) {
      state.openModal = {
        type: action.payload.type,
        sfid: action.payload.sfid
      };
    },

    activeTab(state, action) {
      state.openTab = action.payload.toLowerCase();
    }
  }
});

export default menu.reducer;

export const {activeItem, activeComponent, endComponent, activeModal, activeTab} = menu.actions;
export const getOpenItem = (state) => state.menu.openItem;
export const getOpenComponent = (state) => state.menu.openComponent;
export const getEndComponent = (state) => state.menu.endComponent;
export const getOpenModal = (state) => state.menu.openModal;
export const getOpenTab = (state) => state.menu.openTab;
