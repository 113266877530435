import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Skeleton, Stack, Typography, useTheme} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {SelectMinimumTextField} from '../@extended/SelectTextFields';
import {getEndComponent} from '../../../app/store/reducers/menu';
import {fontAwesomeIcons} from '../../utils/icons';

const DashboardComponent = ({
  data,
  type,
  dashboardObj,
  isLoading = false,
  isSuccess = true,
  createHandleMenuClick,
  isActive,
  onClick = null
}) => {
  const theme = useTheme();
  const endComponent = useSelector(getEndComponent);
  let listObject = null;

  if (type.toLowerCase() == 'settings' && !isActive) {
    listObject = (
      <Typography variant="h6" color="primary" sx={{cursor: 'pointer'}} onClick={onClick}>
        {dashboardObj.name}
      </Typography>
    );
  } else if (data.length <= 1) {
    listObject = (
      <Typography variant="h6" color={isActive ? 'secondary' : 'primary'}>
        {dashboardObj.name}
      </Typography>
    );
  } else if (data.length > 1) {
    listObject = (
      <SelectMinimumTextField isActive={isActive} value={dashboardObj.sfid} data={data} type={type} onClick={createHandleMenuClick} />
    );
  }

  if (isLoading) {
    return <Skeleton variant="text" width={'20%'} height={20} animation="wave" />;
  } else if (isSuccess) {
    return (
      <Stack direction="row" columnGap={1.5} justifyContent="center" alignItems="center">
        {listObject}
        {type.toLowerCase() !== endComponent && (
          <FontAwesomeIcon icon={fontAwesomeIcons.faAngleRightRegular} color={theme.palette.grey[500]} />
        )}
      </Stack>
    );
  } else {
    return <div />;
  }
};

DashboardComponent.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
  dashboardObj: PropTypes.object,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  createHandleMenuClick: PropTypes.func,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

export default DashboardComponent;
