import {loyaltyCloudApi} from './apiSlice';

export const sessionApi = loyaltyCloudApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define all your endpoints here
    getSessionInfo: builder.query({
      query: (token) => ({
        url: '/verify_login',
        method: 'get',
        params: {token}
      })
    }),
    getSsoSessionInfo: builder.query({
      query: (token) => ({
        url: '/verify_sso_login',
        method: 'get',
        params: {token}
      })
    }),
    updateLoginSession: builder.mutation({
      query: (email) => ({
        url: `/email_signin/${email}/`,
        method: 'post'
      }),
      providesTags: () => [{type: 'Session', id: 'LOGIN'}]
    }),
    updateSignUpSession: builder.mutation({
      query: (email) => ({
        url: `/email_sign_up/${email}/`,
        method: 'post'
      }),
      providesTags: () => [{type: 'Session', id: 'SIGNUP'}]
    }),
    updateSsoLoginSession: builder.mutation({
      query: (email) => ({
        url: `/sso_signin/${email}/`,
        method: 'post'
      }),
      providesTags: () => [{type: 'Session', id: 'SSO_LOGIN'}]
    })
  }),
  overrideExisting: false
});

export const {
  useGetSessionInfoQuery,
  useLazyGetSessionInfoQuery,
  useLazyGetSsoSessionInfoQuery,
  useGetSsoSessionInfoQuery,
  useUpdateLoginSessionMutation,
  useUpdateSignUpSessionMutation,
  useUpdateSsoLoginSessionMutation
} = sessionApi;
