// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme) {
  const disabledStyle = {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200]
    }
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      variants: [
        {
          props: {color: 'primary'},
          style: {
            color: theme.palette.grey[700],
            borderColor: theme.palette.grey[700],
            '&:hover': {
              borderColor: theme.palette.grey[200]
            }
          }
        },
        {
          props: {color: 'secondary'},
          style: {
            '&:hover': {
              borderColor: theme.palette.secondary.light
            }
          }
        },
        {
          props: {size: 'large'},
          style: {
            padding: '16px'
          }
        }
      ],
      styleOverrides: {
        root: {
          fontWeight: 400,
          borderRadius: '8px',
          textTransform: 'none'
        },
        contained: {
          ...disabledStyle
        },
        outlined: {
          ...disabledStyle
        }
      }
    }
  };
}
