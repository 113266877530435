// ==============================|| OVERRIDES - LINK ||============================== //

export default function Link() {
  return {
    MuiLink: {
      variants: [
        {
          props: {variant: 'navBar'},
          style: {
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'top',
            textDecoration: 'none',
            color: '#222222',
            padding: '9px 10px',
            textAlign: 'left',
            marginLeft: 'auto',
            marginRight: 'auto',
            lineHeight: '20px',
            letterSpacing: '1px',
            opacity: '0.6',
            transition: 'opacity 200ms ease',
            '&:hover': {
              color: '#32343a',
              fontWeight: 500,
              opacity: 1
            }
          }
        }
      ],
      defaultProps: {
        underline: 'hover'
      }
    }
  };
}
