import axios from 'axios';
import {createApi} from '@reduxjs/toolkit/query/react';

import CONSTANTS from '../../commons/constants/envConstants';
import {getCurrentToken, initializeToken, removeCookie} from '../../commons/utils/cookies';

initializeToken();

const axiosBaseQuery =
  () =>
  async ({url, method, data, params, headers}) => {
    try {
      const baseUrl = CONSTANTS.LC_API_URL;
      const token = await getCurrentToken();
      const configuredHeaders = {
        'X-Session-Token': token,
        'Content-Type': 'application/json',
        LCAPIKey: CONSTANTS.LC_API_KEY,
        ...headers
      };

      const result = await axios({
        url: `${baseUrl}${url}`,
        method,
        data,
        params,
        headers: configuredHeaders
      });

      return {data: result.data?.data ?? result.data};
    } catch (axiosError) {
      const err = axiosError;
      // console.error('API ERROR: ', err);
      let json_error = err.response?.data ? JSON.stringify(err.response?.data) : JSON.stringify(err.message);
      json_error = json_error.toLowerCase();

      if (json_error.includes('session') || json_error.includes('magic link')) {
        removeCookie('session_token');
        removeCookie('expires_at');
        window.location.reload();
      } else {
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message
          }
        };
      }
    }
  };

export const loyaltyCloudApi = createApi({
  reducerPath: 'loyaltyCloudApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getTeamMemberInfo: builder.query({
      query: () => ({
        url: '/session_info/',
        method: 'get'
      })
    }),
    getTeamMemberInfoByEmailOrId: builder.query({
      query: ({email, teamMemberId}) => ({
        url: '/team_member/',
        method: 'get',
        params: {email, team_member_id: teamMemberId}
      })
    }),
    updateTeamMemberInfo: builder.mutation({
      query: ({data, id}) => ({
        url: '/team_member/',
        method: 'patch',
        params: {loyalty_cloud_team_member_id: id},
        headers: {'Content-Type': 'multipart/form-data'},
        data
      })
    }),
    updateTeamMemberPersonalInfo: builder.mutation({
      query: ({data, id}) => ({
        url: '/team_member/',
        method: 'patch',
        params: {loyalty_cloud_team_member_id: id},
        headers: {'Content-Type': 'multipart/form-data'},
        data
      })
    }),
    deleteTeamMemberById: builder.mutation({
      query: (id) => ({
        method: 'delete',
        url: '/team_member/',
        params: {loyalty_cloud_team_member_id: id}
      })
    }),
    getTeamMembersByAccountId: builder.query({
      query: (id) => ({
        url: '/team_members/list/',
        method: 'get',
        params: {account_id: id}
      }),
      transformResponse: (response) =>
        response
          .map((r) => {
            const perms = r['property_permissions']
              .split(',')
              .filter((p) => p)
              .filter((p) => p != 'all' && p != 'blank');

            return {...r, name: r.first_name + ' ' + r.last_name, permissions: perms};
          })
          .sort((a, b) => a.name.localeCompare(b.name))
    }),
    addTeamMember: builder.mutation({
      query: (data) => ({
        method: 'post',
        url: '/team_member/',
        data,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    })
  })
});

export const {
  useLazyGetTeamMemberInfoQuery,
  useLazyGetTeamMembersByAccountIdQuery,
  useUpdateTeamMemberInfoMutation,
  useUpdateTeamMemberPersonalInfoMutation,
  useDeleteTeamMemberByIdMutation,
  useAddTeamMemberMutation,
  useLazyGetTeamMemberInfoByEmailOrIdQuery
} = loyaltyCloudApi;
