import * as React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export const SelectMinimumTextField = ({data, type, onClick, isActive, value}) => {
  let defaultValue = data.length > 0 ? data.find((item) => item.sfid === value) : null;
  defaultValue = defaultValue ? defaultValue : {name: ''};

  return (
    <TextField
      id={`select-minimum-${type}-${defaultValue.sfid}`}
      select
      value={defaultValue.name}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        sx: {
          cursor: 'pointer',
          color: isActive ? 'secondary.main' : 'primary.main',
          '& .MuiSelect-select': {
            backgroundColor: 'transparent',
            '&:focus': {
              backgroundColor: 'transparent'
            }
          },
          '& .MuiSelect-icon': {
            color: isActive ? 'secondary.main' : 'primary.main'
          }
        }
      }}
    >
      {data.map((option, idx) => (
        <MenuItem key={`${idx}-${option.sfid}`} value={option.name} onClick={() => onClick(option, type)}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

SelectMinimumTextField.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  value: PropTypes.string
};
