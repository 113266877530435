// ==============================|| OVERRIDES - CHECKBOX ||============================== //

export default function Checkbox(theme) {
  return {
    MuiCheckbox: {
      variants: [
        {
          props: {color: 'secondary'},
          style: {
            '&.Mui-checked': {
              color: '#3F51B5'
            },
            '&.MuiCheckbox-indeterminate': {
              color: '#3F51B5'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          color: theme.palette.grey[600]
        }
      }
    }
  };
}
