import React from 'react';
// project import
import AuthPage from '../pages/authentication/AuthPage';
import TokenVerification from '../pages/authentication/TokenVerification';

// ==============================|| AUTH ROUTING ||============================== //
const LoginRoutes = [
  {path: 'login', element: <AuthPage />},
  {path: 'verify_login', element: <TokenVerification />},
  {path: 'verify_sso_login', element: <TokenVerification />}
];

export default LoginRoutes;
