const config = {
  basename: '',
  defaultPath: '/campaigns',
  fontFamily: "'Poppins', sans-serif",
  borderRadius: 16
};

export default config;
export const headerHeight = 110;
export const mainContentPaddingWidth = 100;
export const mainContentPaddingHeight = 24;
