// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function IconButton() {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4
        },
        sizeLarge: {
          width: 5.5,
          height: 5.5,
          fontSize: '1.25rem'
        },
        sizeMedium: {
          width: 4.5,
          height: 4.5,
          fontSize: '1rem'
        },
        sizeSmall: {
          width: 3.75,
          height: 3.75,
          fontSize: '0.75rem'
        }
      }
    }
  };
}
