// ==============================|| OVERRIDES - TAB ||============================== //

export default function Paper(theme) {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.06)',
          borderRadius: '16px',
          border: `1px solid ${theme.palette.grey[300]}`
        }
      }
    }
  };
}
