const BASE_API_KEY = process.env.REACT_APP_BASE_API_KEY;
const LC_API_KEY = process.env.REACT_APP_LC_API_KEY;
const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const LC_API_URL = process.env.REACT_APP_LC_API_URL;
const ACTIONS_API_CLIENT_ID = process.env.REACT_APP_ACTIONS_API_STAKE_CLIENT_ID;
const ACTIONS_API_CLIENT_SECRET = process.env.REACT_APP_ACTIONS_API_STAKE_CLIENT_SECRET;
const ACTIONS_API_URL = process.env.REACT_APP_ACTIONS_API_URL;
const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
const MUI_KEY = process.env.REACT_APP_MUI_KEY;
const SENDGRID_API_KEY = process.env.REACT_APP_SENDGRID_API_KEY;
const MESSAGING_SERVICE_API_URL = process.env.REACT_APP_MESSAGING_SERVICE_API_URL;
const MESSAGING_SERVICE_API_KEY = process.env.REACT_APP_MESSAGING_SERVICE_API_KEY;
const DROPBOX_REFRESH_TOKEN = process.env.REACT_APP_DROPBOX_REFRESH_TOKEN;
const DROPBOX_APP_KEY = process.env.REACT_APP_DROPBOX_APP_KEY;
const DROPBOX_APP_SECRET = process.env.REACT_APP_DROPBOX_APP_SECRET;

const CONSTANTS = {
  BASE_API_URL: BASE_API_URL,
  BASE_API_KEY: BASE_API_KEY,
  LC_API_URL: LC_API_URL,
  LC_API_KEY: LC_API_KEY,
  ACTIONS_API_CLIENT_ID: ACTIONS_API_CLIENT_ID,
  ACTIONS_API_CLIENT_SECRET: ACTIONS_API_CLIENT_SECRET,
  ACTIONS_API_URL: ACTIONS_API_URL,
  GOOGLE_MAPS_KEY: GOOGLE_MAPS_KEY,
  MUI_KEY: MUI_KEY,
  SENDGRID_API_KEY: SENDGRID_API_KEY,
  MESSAGING_SERVICE_API_URL: MESSAGING_SERVICE_API_URL,
  MESSAGING_SERVICE_API_KEY: MESSAGING_SERVICE_API_KEY,
  DROPBOX_REFRESH_TOKEN: DROPBOX_REFRESH_TOKEN,
  DROPBOX_APP_KEY: DROPBOX_APP_KEY,
  DROPBOX_APP_SECRET: DROPBOX_APP_SECRET
};

export default CONSTANTS;
