// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function TextField() {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {}
      }
    }
  };
}
