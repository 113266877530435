import logger from 'redux-logger';
import reducer from './reducers/reducer';
import {configureStore} from '@reduxjs/toolkit';

import {coreApiSlice} from '../../features/coreApi/apiSlice';
import {actionsApiSlice} from '../../features/actionsApi/apiSlice';
import {loyaltyCloudApi} from '../../features/loyaltyCloudApi/apiSlice';
import {messagingApiSlice} from '../../features/messagingApi/apiSlice';

const middleware = [coreApiSlice.middleware, actionsApiSlice.middleware, loyaltyCloudApi.middleware, messagingApiSlice.middleware];

// Only add logger middleware in development
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middleware),
  devTools: process.env.NODE_ENV === 'development',
  preloadedState: {}
});
