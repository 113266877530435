import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';

import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import App from './App';
import config from './config';
import reportWebVitals from './reportWebVitals';

import {store} from './app/store/store';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>
);

if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);

  // Evaluating the LCP Score:
  // Good: 2.5 seconds or faster.
  // Needs Improvement: Between 2.5 seconds and 4 seconds.
  // Poor: Greater than 4 seconds.
}
