import React from 'react';

// material-ui
import {Box, ButtonBase} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

// project import
import {activeComponent, activeItem, activeTab, endComponent} from '../../../app/store/reducers/menu';
import {setCampaignsSelected, setCommunitiesSelected, setDashboard} from '../../../app/store/reducers/dashboard';
import {getCampaigns, getCommunities} from '../../../app/store/reducers/entities';

import PositiveBlueLogo from '../../../assets/images/stake-logo-positive-blue-RGB.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const dispatch = useDispatch();
  const campaigns = useSelector(getCampaigns);
  const communities = useSelector(getCommunities);

  const handleClick = () => {
    dispatch(endComponent('campaigns'));
    dispatch(activeItem('portfolio'));
    dispatch(activeComponent('home'));
    dispatch(activeTab('today'));

    const camps = campaigns || [];
    dispatch(setCampaignsSelected(camps));
    const campAmount = camps.length || 0;
    dispatch(setDashboard({type: 'campaigns', data: {sfid: 'all', name: `All campaigns (${campAmount})`}}));

    dispatch(setCommunitiesSelected(communities));
    dispatch(setDashboard({type: 'communities', data: {sfid: 'all', name: `All communities (${communities.length})`}}));
  };

  return (
    <Box
      sx={{
        width: 'fit-content',
        height: '100%',
        position: 'absolute',
        top: '29px',
        left: '40px'
      }}
    >
      <ButtonBase disableRipple onClick={handleClick}>
        <img src={PositiveBlueLogo} alt="stake-logo" width="20" />
      </ButtonBase>
    </Box>
  );
};

export default LogoSection;
