import {combineReducers} from 'redux';

import menu from './menu';
import session from './session';
import alerts from './alerts';
import dashboard from './dashboard';
import entities from './entities';
import loading from './loading';

import {coreApiSlice} from '../../../features/coreApi/apiSlice';
import {actionsApiSlice} from '../../../features/actionsApi/apiSlice';
import {loyaltyCloudApi} from '../../../features/loyaltyCloudApi/apiSlice';
import {messagingApiSlice} from '../../../features/messagingApi/apiSlice';

const reducer = combineReducers({
  menu,
  session,
  alerts,
  dashboard,
  entities,
  loading,
  [coreApiSlice.reducerPath]: coreApiSlice.reducer,
  [loyaltyCloudApi.reducerPath]: loyaltyCloudApi.reducer,
  [actionsApiSlice.reducerPath]: actionsApiSlice.reducer,
  [messagingApiSlice.reducerPath]: messagingApiSlice.reducer
});

export default reducer;
