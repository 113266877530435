// mui
import {styled} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

// ==============================|| HEADER - APP BAR STYLED ||============================== //

const AppBarStyled = styled(AppBar, {shouldForwardProp: (prop) => prop !== 'open'})(({theme}) => ({
  zIndex: 10,
  borderRadius: 0,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

export default AppBarStyled;
