import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, useLocation, useNavigate, useRoutes} from 'react-router-dom';

// project import
import {getAuthorizedStatus} from '../app/store/reducers/session';
import {getCookie} from '../commons/utils/cookies';
import MainLayout from '../layout/MainLayout/MainLayout';
import MinimalLayout from '../layout/MinimalLayout/MinimalLayout';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const ThemeRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthorized = useSelector(getAuthorizedStatus);
  const sessionToken = getCookie('session_token');
  const isAuthenticated = Boolean(isAuthorized || sessionToken);
  const publicPaths = ['/login', '/verify_login', '/verify_sso_login'];

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home');
    } else if (!isAuthenticated && !publicPaths.includes(location.pathname)) {
      navigate('/login');
    }
  }, [isAuthenticated, location.pathname, navigate, sessionToken, isAuthorized]);

  const routesConfig = [
    {
      path: '/',
      element: isAuthenticated ? <MainLayout /> : <MinimalLayout />,
      children: isAuthenticated ? MainRoutes : LoginRoutes
    },
    {path: '*', element: <Navigate to="/" replace />}
  ];

  return useRoutes(routesConfig);
};

export default ThemeRoutes;
