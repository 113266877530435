import React from 'react';
import {BrowserView, MobileView} from 'react-device-detect';
import {Outlet} from 'react-router-dom';
import MobileLayout from '../MainLayout/MobileLayout';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    <BrowserView>
      <Outlet />
    </BrowserView>
    <MobileView>
      <MobileLayout />
    </MobileView>
  </>
);

export default MinimalLayout;
