export const findUserAccountsPermission = (isStakeAdmin, accountSfid, accountsPermissions, accounts) => {
  let accountsAccess = [];

  if (isStakeAdmin) {
    accountsAccess = accounts.map((acc) => acc.sfid);
  } else if (accountsPermissions && accountsPermissions.length > 0) {
    accountsAccess = [accountSfid, ...accountsPermissions];
  } else if (accountSfid) {
    accountsAccess = [accountSfid];
  }

  return accountsAccess;
};
