import React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';

// material-ui
import {Box} from '@mui/material';

import Header from './Header/Header';

import {getCookie} from '../../commons/utils/cookies';
import {activeComponent} from '../../app/store/reducers/menu';
import {getAccountSfid, getAuthorizedStatus} from '../../app/store/reducers/session';
import {useLazyGetAccountsQuery} from '../../features/coreApi/apiSlice';
import {findUserAccountsPermission} from '../../commons/components/MenuDashboard/utils';
import {useLazyGetTeamMemberInfoQuery} from '../../features/loyaltyCloudApi/apiSlice';
import {useGetResidentsByAccountSfidQuery} from '../../features/coreApi/residentsSlice';
import {useLazyGetCampaignsByAccountSfidQuery} from '../../features/actionsApi/campaignsSlice';
import {useLazyGetCommunitiesByAccountSfidQuery} from '../../features/coreApi/communitiesSlice';
import {getCampaigns, setAccounts, setCampaigns, setCommunities, setResidents} from '../../app/store/reducers/entities';
import {headerHeight, mainContentPaddingHeight, mainContentPaddingWidth} from '../../config';
import {getDashboardAccount, setCampaignsSelected, setCommunitiesSelected, setDashboard} from '../../app/store/reducers/dashboard';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(getAuthorizedStatus);
  const dashboardAccount = useSelector(getDashboardAccount);
  const sessionToken = getCookie('session_token');
  const [updatedAccountsPermissions, setUpdatedAccountsPermissions] = useState();
  const entitiesCampaigns = useSelector(getCampaigns);
  const sessionAccountSfid = useSelector(getAccountSfid);

  const [fetchTeamMemberInfo, {data: teamMember, isSuccess, isFetching, isLoading}] = useLazyGetTeamMemberInfoQuery();
  const propertyPermissions = teamMember?.property_permissions;
  const accountsPermissions = teamMember?.additional_accounts_permissions;
  const isStakeAdmin = teamMember?.master;
  const lcTeamMemberId = teamMember?.id;
  const accountSfid = dashboardAccount?.sfid || teamMember?.associated_sfid;
  const associatedSfid = sessionAccountSfid || teamMember?.associated_sfid;
  const role = teamMember?.role;
  const isAdministrator =
    role === 'Administrator' ||
    teamMember?.property_permissions.includes('all') ||
    teamMember?.property_permissions.includes('blank') ||
    teamMember?.property_permissions.length == 0;

  const [fetchAccounts, {data: acResponse = [], isSuccess: isAcSuccess, isLoading: isAcLoading, isFetching: isAcFetching}] =
    useLazyGetAccountsQuery();
  const [
    fetchCommunities,
    {data: comResponse = [], isSuccess: isComSuccess, isLoading: isComLoading, isFetching: isComFetching, isError: isComError}
  ] = useLazyGetCommunitiesByAccountSfidQuery();
  const [
    fetchCampaigns,
    {data: camResponse = {}, isSuccess: isCamSuccess, isLoading: isCamLoading, isFetching: isCamFetching, isError: isCamError}
  ] = useLazyGetCampaignsByAccountSfidQuery();

  const {
    data: resResponse = [],
    isSuccess: isResSuccess,
    isLoading: isResLoading,
    isFetching: isResFetching
  } = useGetResidentsByAccountSfidQuery(accountSfid, {skip: !accountSfid});

  useEffect(() => {
    if (sessionToken) {
      fetchTeamMemberInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionToken]);

  useEffect(() => {
    dispatch(activeComponent('home'));
    fetchAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountSfid) {
      fetchCommunities(accountSfid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSfid]);

  useEffect(() => {
    if (accountSfid) {
      fetchCampaigns(accountSfid);
    }
  }, [accountSfid, fetchCampaigns]);

  useEffect(() => {
    if (isAcSuccess && !isAcFetching && !isAcLoading) {
      const perms = findUserAccountsPermission(isStakeAdmin, associatedSfid, accountsPermissions, acResponse);
      setUpdatedAccountsPermissions(perms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStakeAdmin, accountSfid, accountsPermissions, propertyPermissions, isAcFetching, isAcLoading, isAcSuccess]);

  useEffect(() => {
    if (updatedAccountsPermissions) {
      const selectedAccounts = acResponse.filter((acc) => updatedAccountsPermissions.includes(acc.sfid));
      dispatch(setAccounts(selectedAccounts));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAccountsPermissions]);

  useEffect(() => {
    if (!isComFetching && !isComLoading && isComSuccess) {
      if (isStakeAdmin || (updatedAccountsPermissions && updatedAccountsPermissions.length > 1)) {
        dispatch(setCommunities(comResponse));
        dispatch(setCommunitiesSelected(comResponse));
      } else {
        if (isAdministrator) {
          dispatch(setCommunities(comResponse));
          dispatch(setCommunitiesSelected(comResponse));
        } else {
          const filteredPropertyPermissions = propertyPermissions?.filter((perm) => perm && perm != 'all' && perm != 'blank');
          const selectedCommunities = comResponse.filter((com) => filteredPropertyPermissions?.includes(com.sfid));
          dispatch(setCommunities(selectedCommunities));
          dispatch(setCommunitiesSelected(selectedCommunities));
        }
      }
    } else if (isComError) {
      dispatch(setCommunities([]));
      dispatch(setCommunitiesSelected([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyPermissions, isComFetching, isComLoading, isComSuccess, isStakeAdmin, propertyPermissions]);

  useEffect(() => {
    if (!isCamFetching && !isCamLoading && isCamSuccess) {
      if (isStakeAdmin) {
        const camps = camResponse?.result || [];
        dispatch(setCampaigns(camps));
        const campAmount = camResponse?.result.length || 0;
        dispatch(setDashboard({type: 'campaigns', data: {sfid: 'all', name: `All campaigns (${campAmount})`}}));
      } else if (comResponse.length > 0) {
        let filteredPropertyPermissions = [];
        if (isAdministrator) {
          filteredPropertyPermissions = comResponse.map((com) => com.sfid);
        } else {
          filteredPropertyPermissions = propertyPermissions.filter((perm) => perm && perm != 'all' && perm != 'blank');
        }
        const selectedCommunities = comResponse.filter((com) => filteredPropertyPermissions.includes(com.sfid));
        const communitiesSfids = selectedCommunities.map((com) => com.sfid);
        const selectedCampaigns = camResponse?.result.filter((camp) => communitiesSfids.includes(camp.property_sfid));
        dispatch(setCampaigns(selectedCampaigns));
      }
    } else if (isCamError) {
      dispatch(setCampaigns([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCamFetching, isCamLoading, isCamSuccess, isStakeAdmin, isComFetching, isComLoading, isComSuccess, propertyPermissions]);

  useEffect(() => {
    if (isStakeAdmin) {
      dispatch(setCampaignsSelected(entitiesCampaigns));
    } else if (comResponse.length > 0) {
      dispatch(setCampaignsSelected(entitiesCampaigns));
    } else {
      dispatch(setCampaignsSelected([]));
    }
  }, [entitiesCampaigns, dashboardAccount, isStakeAdmin, comResponse]);

  useEffect(() => {
    if (!isResFetching && !isResLoading && isResSuccess) {
      if (isStakeAdmin) {
        dispatch(setResidents(resResponse));
      } else if (comResponse.length > 0) {
        let filteredPropertyPermissions = [];

        if (isAdministrator) {
          filteredPropertyPermissions = comResponse.map((com) => com.sfid);
        } else {
          filteredPropertyPermissions = propertyPermissions.filter((perm) => perm && perm != 'all' && perm != 'blank');
        }
        const selectedCommunities = comResponse.filter((com) => filteredPropertyPermissions.includes(com.sfid));
        const communitiesSfids = selectedCommunities.map((com) => com.sfid);
        const selectedResidents = resResponse.filter((res) => communitiesSfids.includes(res.property__c));
        dispatch(setResidents(selectedResidents));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResFetching, isResLoading, isResSuccess, isComFetching, isComLoading, isComSuccess, propertyPermissions, isStakeAdmin]);

  useEffect(() => {
    if (isAuthorized && acResponse.length > 0 && accountSfid && lcTeamMemberId) {
      const company = acResponse.find((acc) => acc.sfid === accountSfid);
      const companyName = company ? company.name : '';

      window.intercomSettings = {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: lcTeamMemberId,
        company: {
          company_id: accountSfid,
          name: companyName
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, isAcFetching, isAcLoading, isAcSuccess, accountSfid, lcTeamMemberId]);

  const mainComponent = useMemo(() => {
    if (!isLoading && !isFetching && isSuccess) {
      return (
        <>
          <Header />
          <Box
            component="main"
            sx={{
              marginTop: `${headerHeight}px`,
              width: '100%',
              p: `${mainContentPaddingHeight}px ${mainContentPaddingWidth}px`
            }}
          >
            <Outlet />
          </Box>
        </>
      );
    }
  }, [isSuccess, isLoading, isFetching]);

  return <Box sx={{display: 'flex', width: '100%'}}>{mainComponent}</Box>;
};

export default MainLayout;
