import {createSlice} from '@reduxjs/toolkit';

import {reviewsApi} from '../../../features/loyaltyCloudApi/reviewsSlice';
import {residentsApi} from '../../../features/coreApi/residentsSlice';
import {campaignsSlice} from '../../../features/actionsApi/campaignsSlice';
import {loyaltyCloudApi} from '../../../features/loyaltyCloudApi/apiSlice';
import {contactListApi} from '../../../features/loyaltyCloudApi/contactListSlice';
import dayjs from 'dayjs';

const initialState = {
  accounts: [],
  communities: [],
  campaigns: [],
  residents: [],
  reviews: [],
  teamMembers: [],
  contactLists: []
};

// ==============================|| SLICE - ENTITIES ||============================== //
const entities = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setCommunities: (state, action) => {
      state.communities = action.payload;
    },
    setCampaigns: (state, action) => {
      const formattedCampaigns = [];

      for (const payloadCampaign of action.payload) {
        let newCampaign = {...payloadCampaign};
        if (newCampaign.name) {
          if (newCampaign.name.includes('StakePro')) {
            newCampaign.status = 'Active';
            newCampaign.display_subtype = 'StakePro';
          } else if (newCampaign.name.includes('StakeFree')) {
            newCampaign.status = 'Active';
            newCampaign.display_subtype = 'StakeFree';
          }
        } else if (newCampaign.subtype == 'Stake1') {
          newCampaign.status = 'Active';
          newCampaign.display_subtype = 'Stake1';
        }

        if (newCampaign.status == 'Paused') {
          newCampaign.status = 'Inactive';
        }

        formattedCampaigns.push(newCampaign);
      }

      state.campaigns = formattedCampaigns.sort((a, b) => a.name - b.name);
    },
    setResidents: (state, action) => {
      state.residents = action.payload;
    },
    setTeamMembers: (state, action) => {
      state.teamMembers = action.payload;
    },
    resetContactLists: (state) => {
      state.contactLists = [];
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(reviewsApi.endpoints.getReviews.matchFulfilled, (state, {payload}) => {
        state.reviews = payload.filter((v) => v.rating <= 5).sort((a, b) => dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf());
      })
      .addMatcher(reviewsApi.endpoints.editReview.matchFulfilled, (state, {payload}) => {
        const reviews = state.reviews.map((review) => (review.id === payload.id ? payload : review));
        state.reviews = reviews;
      })
      .addMatcher(reviewsApi.endpoints.createReview.matchFulfilled, (state, {payload}) => {
        state.reviews = [payload, ...state.reviews];
      })
      .addMatcher(residentsApi.endpoints.updateResidentsPayments.matchFulfilled, (state, {payload}) => {
        const resident = payload.length > 0 ? payload[0] : null;
        if (resident) {
          const residents = state.residents.map((r) => (r.sfid === resident.sfid ? resident : r));
          state.residents = residents;
        }
      })
      .addMatcher(residentsApi.endpoints.updateResidentInfo.matchFulfilled, (state, {payload}) => {
        if (payload) {
          const residents = state.residents.map((r) => (r.sfid === payload.sfid ? payload : r));
          state.residents = residents;
        }
      })
      .addMatcher(campaignsSlice.endpoints.postRecommendedCampaign.matchFulfilled, (state, {payload}) => {
        const formattedCampaigns = [];
        const createdCampaign = payload[0];
        const campaigns = [...state.campaigns].filter((c) => c.id != createdCampaign.id);

        for (const payloadCampaign of [...campaigns, createdCampaign]) {
          let newCampaign = {
            ...payloadCampaign,
            property_sfid: payloadCampaign.rental_community.sfid,
            sfid: payloadCampaign.id,
            name: payloadCampaign?.name || `${payloadCampaign.rental_community.name} - ${payloadCampaign.subtype}`
          };

          if (newCampaign.name) {
            if (newCampaign.name.includes('StakePro')) {
              newCampaign.status = 'Active';
              newCampaign.display_subtype = 'StakePro';
            } else if (newCampaign.name.includes('StakeFree')) {
              newCampaign.status = 'Active';
              newCampaign.display_subtype = 'StakeFree';
            }
          } else if (newCampaign.subtype == 'Stake1') {
            newCampaign.status = 'Active';
          }

          if (newCampaign.status == 'Paused') {
            newCampaign.status = 'Inactive';
          }

          formattedCampaigns.push(newCampaign);
        }

        state.campaigns = formattedCampaigns.sort((a, b) => a.name - b.name);
      })
      .addMatcher(loyaltyCloudApi.endpoints.updateTeamMemberInfo.matchFulfilled, (state, {payload}) => {
        const updateTM = {
          ...payload,
          permissions: payload['property_permissions']
            .split(',')
            .filter((p) => p)
            .filter((p) => p != 'all' && p != 'blank'),
          name: payload.first_name + ' ' + payload.last_name
        };
        const teamMembers = state.teamMembers.map((r) => (r.id === updateTM.id ? updateTM : r));
        state.teamMembers = teamMembers;
      })
      .addMatcher(loyaltyCloudApi.endpoints.addTeamMember.matchFulfilled, (state, {payload}) => {
        const newTM = {
          ...payload,
          permissions: payload['property_permissions']
            .split(',')
            .filter((p) => p)
            .filter((p) => p != 'all' && p != 'blank'),
          name: payload.first_name + ' ' + payload.last_name
        };
        const teamMembers = [newTM, ...state.teamMembers];
        state.teamMembers = teamMembers.sort((a, b) => a.name.localeCompare(b.name));
      })
      .addMatcher(loyaltyCloudApi.endpoints.deleteTeamMemberById.matchFulfilled, (state, {meta}) => {
        const teamMembers = state.teamMembers.filter((r) => r.id !== meta.arg.originalArgs);
        state.teamMembers = teamMembers;
      })
      .addMatcher(contactListApi.endpoints.getContactList.matchFulfilled, (state, {payload}) => {
        state.contactLists = [...state.contactLists.filter((r) => r.id !== payload.id), payload];
      })
      .addMatcher(contactListApi.endpoints.deleteContactList.matchFulfilled, (state, {meta}) => {
        const contactLists = state.contactLists.filter((r) => r.id !== meta.arg.originalArgs);
        state.contactLists = contactLists;
      })
      .addMatcher(contactListApi.endpoints.updateContactListContacts.matchFulfilled, (state, {payload}) => {
        const {contact_list_id, contact_sfids} = payload.data || payload;
        const updatedList = state.contactLists.map((r) => (r.id == contact_list_id ? {...r, contact_sfids} : r));
        state.contactLists = updatedList;
      })
      .addMatcher(contactListApi.endpoints.createContactList.matchFulfilled, (state, {payload}) => {
        state.contactLists = [payload, ...state.contactLists];
      });
  }
});

export const {setAccounts, setCommunities, setCampaigns, setResidents, setTeamMembers, resetContactLists} = entities.actions;

export default entities.reducer;
export const getAccounts = (state) => state.entities.accounts;
export const getCommunities = (state) => state.entities.communities;
export const getCampaigns = (state) => state.entities.campaigns;
export const getResidents = (state) => state.entities.residents;
export const getReviews = (state) => state.entities.reviews;
export const getTeamMembers = (state) => state.entities.teamMembers;
export const getEntitiesContactLists = (state) => state.entities.contactLists;
