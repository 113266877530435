import axios from 'axios';
import {createApi} from '@reduxjs/toolkit/query/react';

import CONSTANTS from '../../commons/constants/envConstants';
import {getCurrentToken, initializeToken} from '../../commons/utils/cookies';

initializeToken();

const axiosBaseQuery =
  () =>
  async ({url, method, data, params, headers}) => {
    const baseUrl = CONSTANTS.ACTIONS_API_URL;
    const sessionToken = await getCurrentToken();

    const fetchToken = async () => {
      try {
        const tokenResult = await axios({
          url: `${baseUrl}security/token`,
          method: 'post',
          data: JSON.stringify({
            STAKE_CLIENT_ID: CONSTANTS.ACTIONS_API_CLIENT_ID,
            STAKE_CLIENT_SECRET: CONSTANTS.ACTIONS_API_CLIENT_SECRET
          }),
          headers: {'Content-Type': 'application/json'}
        });

        if (tokenResult?.data && tokenResult.data?.access_token) {
          return tokenResult.data.access_token;
        } else {
          throw new Error('Failed to fetch token');
        }
      } catch (error) {
        console.error('Error fetching token:', error);
        throw error;
      }
    };

    const executeQuery = async (token) => {
      try {
        const configuredHeaders = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Session-Token': sessionToken,
          ...headers
        };

        const result = await axios({
          url: `${baseUrl}${url}`,
          method,
          data,
          params,
          headers: configuredHeaders
        });

        return {data: result.data?.data ?? result.data};
      } catch (axiosError) {
        const err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message
          }
        };
      }
    };

    try {
      const token = await fetchToken();
      return await executeQuery(token);
    } catch (error) {
      return {
        error: {
          status: 'FETCH_ERROR',
          data: error.message || 'Failed to fetch token'
        }
      };
    }
  };

export const actionsApiSlice = createApi({
  reducerPath: 'actionsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSecurityToken: builder.query({
      query: () => ({
        url: 'security/token',
        method: 'post',
        data: JSON.stringify({
          STAKE_CLIENT_ID: CONSTANTS.ACTIONS_API_CLIENT_ID,
          STAKE_CLIENT_SECRET: CONSTANTS.ACTIONS_API_CLIENT_SECRET
        })
      })
    })
  })
});

export const {useLazyGetSecurityTokenQuery} = actionsApiSlice;
