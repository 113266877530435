import React from 'react';
// project import
import {useDispatch} from 'react-redux';
import {useEffect} from 'react';

import {LicenseInfo} from '@mui/x-license';

import {getCookie} from './commons/utils/cookies';
import {setAuthorization} from './app/store/reducers/session';

import Routes from './routes/ThemeRoutes';
import ThemeCustomization from './themes/ThemeCustomization';
import ScrollTop from './commons/components/ScrollTop';
import {IntercomProvider} from 'react-use-intercom';
import CONSTANTS from './commons/constants/envConstants';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const license = CONSTANTS.MUI_KEY;
LicenseInfo.setLicenseKey(license);

const App = () => {
  const dispatch = useDispatch();
  const sessionToken = getCookie('session_token');

  useEffect(() => {
    dispatch(setAuthorization());
    // eslint-disable-next-line
  }, [sessionToken]);

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
      <ThemeCustomization>
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeCustomization>
    </IntercomProvider>
  );
};

export default App;
