// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors) => {
  const {blue, red, gold, cyan, green, grey, stake} = colors;

  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  const stakeColors = {
    main: stake[0],
    light: stake[1]
  };

  return {
    primary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      300: greyColors[300],
      light: stakeColors.light,
      400: greyColors[400],
      600: greyColors[600],
      main: greyColors[700],
      800: greyColors[800],
      dark: greyColors[900],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0]
    },
    secondary: {
      lighter: blue[0],
      100: blue[1],
      200: blue[2],
      light: blue[3],
      400: blue[4],
      main: stakeColors.main,
      dark: blue[6],
      700: blue[7],
      darker: blue[8],
      900: blue[9],
      contrastText
    },
    error: {
      lighter: red[0],
      light: red[2],
      main: '#F14242',
      dark: red[7],
      darker: red[9],
      contrastText
    },
    warning: {
      lighter: gold[0],
      light: gold[3],
      main: gold[5],
      dark: gold[7],
      darker: gold[9],
      contrastText: greyColors[100]
    },
    info: {
      lighter: cyan[0],
      light: cyan[3],
      main: cyan[5],
      dark: cyan[7],
      darker: cyan[9],
      contrastText
    },
    success: {
      lighter: green[0],
      light: green[3],
      main: '#11A10E',
      dark: green[7],
      darker: green[9],
      contrastText
    },
    grey: greyColors,
    chart: {
      0: '#3c84c7',
      1: '#64c6e4',
      2: '#f8e377',
      3: '#7d618a',
      4: '#f1a025',
      5: '#dec5e7',
      6: '#523a3a',
      7: '#ff87b2',
      8: '#0d7e1f',
      9: '#ffcb44'
    },
    messaging: {
      emailIcon: '#f93155',
      textIcon: '#3169f9',
      pushIcon: '#31f975'
    }
  };
};

export default Theme;
