import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import DashboardComponent from '../dashboardComponent';
import {activeComponent, endComponent, getEndComponent, getOpenComponent} from '../../../../app/store/reducers/menu';

const SettingsHeader = () => {
  const dispatch = useDispatch();
  const endComp = useSelector(getEndComponent);
  const openComponent = useSelector(getOpenComponent);

  const dashboardObj = useMemo(() => {
    if (openComponent && openComponent !== 'settings') {
      return {sfid: openComponent?.toLowerCase() || null, name: openComponent?.charAt(0).toUpperCase() + openComponent?.slice(1) || null};
    } else {
      return {sfid: null, name: null};
    }
  }, [openComponent]);

  const settingsDashboard = {sfid: 'settings', name: 'Settings'};

  const handelSettingsClick = () => {
    dispatch(activeComponent('settings'));
    dispatch(endComponent('settings'));
  };

  return (
    <>
      <DashboardComponent
        type={settingsDashboard.name}
        dashboardObj={settingsDashboard}
        isLoading={false}
        isSuccess={true}
        data={[]}
        createHandleMenuClick={() => {}}
        isActive={endComp == settingsDashboard.sfid}
        onClick={handelSettingsClick}
      />
      {dashboardObj.sfid && (
        <DashboardComponent
          type={dashboardObj.name}
          dashboardObj={dashboardObj}
          isLoading={false}
          isSuccess={true}
          data={[]}
          createHandleMenuClick={() => {}}
          isActive={endComp == dashboardObj.sfid}
        />
      )}
    </>
  );
};

export default SettingsHeader;
